import heroImage from "../../../assets/accueilImages/hero/heroImage.webp";
import { FaArrowRight } from "react-icons/fa";
import { PrimaryButton } from "../../../ui";

const HeroSection = () => {
  return (
    <div className="relative h-[416px] lg:h-[810px] text-white overflow-hidden my-[23px]">
      <div className="absolute inset-0">
        <img
          src={heroImage}
          alt="Hero background showcasing medical services with a focus on urgent care"
          className="object-cover object-center min-w-[430px] h-[416px] lg:w-full lg:h-[810px]"
        />
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>

      <div className="relative z-10 flex flex-col justify-center items-center mt-[257px] lg:mt-[352px] text-center">
        <h1 className="text-[22px] lg:text-[64px] font-bold leading-tight mb-[15.78px] lg:mb-[36px]">
          Votre santé, notre priorité
        </h1>
        <p className="text-[15px] lg:text-[36px] text-gray-300 mb-[15.78px] lg:mb-[36px]">
          Services d'urgence médicale 24/7
        </p>
        <div className="flex flex-col lg:flex-row gap-[10px] lg:gap-[20px] lg:mt-[10px]">
          <PrimaryButton
            className="bg-[#F1F5FD] text-[#2E3F91] border-[#2E3F91] border-2 w-[126.42px] h-[45px] text-[16px] flex items-center justify-center hidden lg:flex"
            name={
              <>
                Voir sur la carte
                <FaArrowRight className="ml-[3px] my-auto w-[12px] h-[12px]" />
              </>
            }
            variant="secondaryButton"
            onClick={() =>
              window.open("https://maps.app.goo.gl/cbpL8BCp7DhAiyDk8", "_blank")
            }
          />
          <PrimaryButton
            className="bg-[#25d366] w-[126.42px] h-[45px] text-[16px] flex items-center justify-center"
            name={
              <>
                Appelez Nous
                <FaArrowRight className="ml-[3px] my-auto w-[12px] h-[12px]" />
              </>
            }
            variant="primaryButton"
            onClick={() => (window.location.href = "tel:+212656566358")}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
