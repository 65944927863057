import React from "react";
import { FaWhatsapp, FaArrowRight } from "react-icons/fa";
import icon from "../../../assets/accueilImages/QuiSommesNousHeroImg/Icon Label.svg";
import doctor from "../../../assets/accueilImages/QuiSommesNousHeroImg/doctor.webp";
import ambulance from "../../../assets/accueilImages/QuiSommesNousHeroImg/Ambulance.webp";
import { PrimaryButton } from "../../../ui";

const QuiSommesNousHero = () => {
  return (
    <section className="container mx-auto bg-white">
      {/* Desktop Version (Hidden on mobile) */}
      <div className="hidden lg:flex flex-row items-center">
        {/* Image Section */}
        <div className="relative flex justify-center items-center lg:w-[567px] w-full">
          <img
            src={doctor}
            alt="Doctor in background with medical services"
            className="absolute inset-0 object-contain w-full lg:w-[567px] lg:h-[478px] h-auto"
          />
          <img
            src={ambulance}
            alt="Ambulance"
            className="relative z-10 lg:top-10 w-[85%] lg:w-[485px] lg:h-[485px] h-auto"
          />
        </div>
        {/* Text Section */}
        <div className="lg:w-[528px] w-full mx-auto text-left lg:pl-8">
          <div className="mb-[12px] flex items-center justify-start">
            <div className="flex items-center justify-center text-[#547FDD] rounded-full w-[36px] h-[36px]">
              <img src={icon} alt="Icon representing our services" />
            </div>
            <span className="ml-[12px] text-[#547FDD] text-[18px]">
              À Propos De Nous
            </span>
          </div>
          <h2 className="text-[35px] font-semibold mb-[12px]">
            Qui Sommes-Nous
          </h2>
          <p className="text-[#6B6B6B] text-[16px] lg:w-[528px] w-full mb-6 font-medium">
            <span className="font-bold">Injad</span> est une société marocaine
            basée à Khouribga, spécialisée dans les services d'ambulance et
            d'urgence médicale. Notre mission est de fournir une assistance
            rapide et de qualité à nos clients, que ce soit en situation
            d'urgence ou pour un besoin médical à domicile.
          </p>
          <hr className="lg:w-[528px] w-full h-[2px] bg-[#547FDD] border-0 rounded my-[32px]" />
          <div className="flex flex-row items-start space-x-2">
            {/* WhatsApp Button */}
            <PrimaryButton
              name={
                <>
                  <FaWhatsapp className="mr-2 text-[22px]" />
                  Contactez Nous
                </>
              }
              variant="whatsappSecondaryButton"
              onClick={() => (window.location.href = "tel:+212656566358")}
            />
            {/* See More Button */}
            <PrimaryButton
              name={
                <>
                  Voir plus
                  <FaArrowRight className="w-[12px] ml-[3px]" />
                </>
              }
              variant="primaryButton"
              onClick={() => window.open("/#")}
              className={"bg-[#2E3F91]"}
            />
          </div>
        </div>
      </div>

      {/* Mobile Version (Hidden on desktop) */}
      <div className="flex flex-col lg:hidden items-center">
        {/* Image Section */}
        <div className="relative flex justify-center items-center w-full">
          <img
            src={doctor}
            alt="Doctor in background with medical services"
            className="absolute inset-0 object-contain w-full lg:w-[567px] lg:h-[478px] h-auto"
          />
          <img
            src={ambulance}
            alt="Ambulance"
            className="relative z-10 lg:top-10 w-[85%] lg:w-[485px] lg:h-[485px] h-auto"
          />
        </div>
        {/* Text Section */}
        <div className="w-[370px] items-start mx-auto text-left mt-4">
          <div className="mb-[8.41px] flex items-center justify-left">
            <div className="flex items-center justify-left text-[#547FDD] rounded-full w-[25.23px] h-[25.23px]">
              <img src={icon} alt="Icon representing our services" />
            </div>
            <span className="ml-[8.41px] font-poppins text-[#547FDD] text-[12px]">
              À Propos De Nous
            </span>
          </div>
          <h2 className="text-[24px] font-poppins font-semibold mb-[8.41px]">
            Qui Sommes-Nous
          </h2>
          <p className="text-[#6B6B6B] font-poppins text-[12px] w-full mb-[22.42px] font-medium">
            <span className="font-bold">Injad</span> est une société marocaine
            basée à Khouribga, spécialisée dans les services d'ambulance et
            d'urgence médicale. Notre mission est de fournir une assistance
            rapide et de qualité à nos clients, que ce soit en situation
            d'urgence ou pour un besoin médical à domicile.
          </p>
          <hr className="w-full h-[2px] bg-[#547FDD] border-0 rounded mb-[32px]" />
          <div className="flex flex-row items-center space-x-2">
            {/* WhatsApp Button */}
            <PrimaryButton
              name={
                <>
                  <FaWhatsapp className="mr-2 text-[22px]" />
                  Contactez Nous
                </>
              }
              variant="whatsappSecondaryButton"
              onClick={() => (window.location.href = "tel:+212656566358")}
              className={"w-[181.75px] h-[38.5px] text-[14.61px]"}
            />
            {/* See More Button */}
            <PrimaryButton
              name={
                <>
                  Voir plus
                  <FaArrowRight className="w-[12px] ml-[3px]" />
                </>
              }
              variant="primaryButton"
              onClick={() => window.open("/#")}
              className={"bg-[#2E3F91] w-[181.75px] h-[38.5px] text-[14.61px]"}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuiSommesNousHero;
