const buttonStyles = {
  whatsappPrimaryButton:
    "flex items-center bg-[#25d366] text-white px-[18px] h-[42px] rounded-[6px] font-md text-[16px]",
  whatsappSecondaryButton:
    "flex justify-center items-center lg:h-[45.5px] lg:w-[199px] text-[#25D366] bg-white border-[2px] border-[#25D366] text-[16px] rounded-[6px] hover:bg-green-200 transition",
  primaryButton:
    "flex justify-center items-center text-white font-normal border-[#2E3F91] rounded-[6px] lg:text-[16px] lg:w-[169px] lg:h-[45px] lg:px-[18px]",
  secondaryButton:
    "flex justify-center items-center text-white bg-[#F1F5FD]/50 font-normal border-[#2E3F91] border-2 rounded-[6px] text-[#2E3F91] text-[16px] w-[169px] h-[45px] px-[18px]",
};

export const PrimaryButton = ({
  name,
  className,
  variant = "primary",
  ...props
}) => {
  const mergeClasses = (...classes) => classes.filter(Boolean).join(" ");
  const finalClassName = mergeClasses(buttonStyles[variant], className);

  return (
    <button type="button" className={finalClassName} {...props}>
      {name}
    </button>
  );
};
