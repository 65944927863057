import {
  HeroSection,
  QuiSommesNousHero,
  NosServicesSection,
  NosValeurs,
  CommentCaMarcheSection,
  GalleryComponent,
  TemoignageSlider,
} from "../../components/accueilComponents";

const Accueil = () => {
  return (
    <>
      <div id="heroSection">
        <HeroSection />
      </div>
      <div id="quiSommesNousHero">
        <QuiSommesNousHero />
      </div>
      <div id="nosServicesSection">
        <NosServicesSection />
      </div>
      <div id="nosValeurs">
        <NosValeurs />
      </div>
      <div id="commentCaMarcheSection">
        <CommentCaMarcheSection />
      </div>
      <div id="galleryComponent">
        <GalleryComponent />
      </div>
      <div id="temoignageSlider">
        <TemoignageSlider />
      </div>
    </>
  );
};

export default Accueil;
