import { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import Karim from "../../../assets/accueilImages/testimonials/Karim ELGOURI.jpeg";
import Said from "../../../assets/accueilImages/testimonials/Said HERRAR.jpeg";

// Sample data for the testimonials
const testimonials = [
  {
    name: "Karim ELGOURI",
    designation: "Compétent et très attentif",
    message:
      "Je tiens à remercier toute l'équipe d'Injad pour leur réactivité et leur professionnalisme. Lors d'une urgence médicale, leur intervention rapide a permis de stabiliser mon état en attendant d'arriver à l'hôpital. Le personnel était rassurant, compétent et très attentif. Grâce à leur intervention, j'ai pu recevoir les soins nécessaires à temps. Merci encore pour votre engagement envers la santé des citoyens de Khouribga.",
    platform: "facebook",
    profileImage: Karim,
  },
  {
    name: "Said HERRAR",
    designation: "Rapidité et leur Professionnalisme",
    message:
      "Injad a littéralement sauvé la vie de mon père lors d'une crise cardiaque. Leur équipe est arrivée en un temps record et a pris en charge la situation avec une grande expertise. Ils ont réussi à stabiliser son état avant de l'amener à l'hôpital, où il a reçu des soins critiques. Sans leur rapidité et leur professionnalisme, je ne sais pas ce qui se serait passé. Je leur en suis infiniment reconnaissante.",
    platform: "facebook",
    profileImage: Said,
  },
];

const TemoignageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  return (
    <div className="px-4 mb-[80px] relative">
      <div className="text-center mb-[50px]">
        <h2 className="text-[32px] md:text-[48px] font-semibold font-poppins text-[#1F264C]">
          Témoignages
        </h2>
      </div>

      {/* PC View: Arrows on the sides */}
      <div className="hidden sm:flex justify-between items-center">
        <button
          onClick={handlePrev}
          className={`z-10 p-[11px] ml-[166.5px] rounded-[6px] border border-[#3F65D1] shadow hover:shadow-md ${
            currentIndex === 0
              ? "cursor-not-allowed bg-[#F1F5FD]"
              : "bg-[#3F65D1]"
          }`}
          disabled={currentIndex === 0}
        >
          <FaArrowLeft
            className={`${
              currentIndex === 0 ? "text-[#3F65D1]" : "text-white"
            }`}
          />
        </button>
        <div className="w-[785px] bg-white rounded-lg border border-[#E1E4ED] mx-[126px] flex items-center">
          <div className="flex flex-col justify-center items-center w-[149px] h-[246px] ml-[32.5px]">
            <img
              src={testimonials[currentIndex].profileImage}
              alt={testimonials[currentIndex].name}
              className="w-[81px] h-[85px] rounded-full object-cover mb-[30px]"
            />
            <p className="text-[16px] font-semibold font-poppins text-[#252628] text-center mb-[12px]">
              {testimonials[currentIndex].name}
            </p>
            <p className="text-[12px] font-normal font-poppins text-[#252628] text-center">
              {testimonials[currentIndex].designation}
            </p>
          </div>
          <div className="flex-grow w-[595px] px-[24px]">
            <h3 className="text-[18px] font-poppins font-bold text-[#252628] capitalize">
              {testimonials[currentIndex].platform}
            </h3>
            <p className="mt-[28px] font-poppins text-[14px] leading-[22px] text-[#5A5A5A]">
              {testimonials[currentIndex].message}
            </p>
          </div>
        </div>
        <button
          onClick={handleNext}
          className={`z-10 p-[11px] mr-[166.5px] rounded-[6px] border border-[#3F65D1] shadow hover:shadow-md ${
            currentIndex === testimonials.length - 1
              ? "cursor-not-allowed bg-[#F1F5FD]"
              : "bg-[#3F65D1]"
          }`}
          disabled={currentIndex === testimonials.length - 1}
        >
          <FaArrowRight
            className={`${
              currentIndex === testimonials.length - 1
                ? "text-[#3F65D1]"
                : "text-white"
            }`}
          />
        </button>
      </div>

      {/* Mobile View: Arrows on the top */}
      <div className="sm:hidden relative flex flex-col justify-center items-center">
        <div className="absolute mx-auto w-[82px] top-[-40px] left-0 right-0 flex justify-between">
          <button
            onClick={handlePrev}
            className={`p-[8px] rounded-[6px] border border-[#3F65D1] shadow hover:shadow-md ${
              currentIndex === 0
                ? "cursor-not-allowed bg-[#F1F5FD]"
                : "bg-[#3F65D1]"
            }`}
            disabled={currentIndex === 0}
          >
            <FaArrowLeft
              className={`${
                currentIndex === 0 ? "text-[#3F65D1]" : "text-white"
              }`}
            />
          </button>
          <button
            onClick={handleNext}
            className={`p-[8px] rounded-[6px] border border-[#3F65D1] shadow hover:shadow-md ${
              currentIndex === testimonials.length - 1
                ? "cursor-not-allowed bg-[#F1F5FD]"
                : "bg-[#3F65D1]"
            }`}
            disabled={currentIndex === testimonials.length - 1}
          >
            <FaArrowRight
              className={`${
                currentIndex === testimonials.length - 1
                  ? "text-[#3F65D1]"
                  : "text-white"
              }`}
            />
          </button>
        </div>
        <div className="w-full items-center text-center bg-white rounded-lg border border-[#E1E4ED] p-4">
          <img
            src={testimonials[currentIndex].profileImage}
            alt={testimonials[currentIndex].name}
            className="w-[60px] h-[60px] mx-auto rounded-full object-cover mb-[16px]"
          />
          <p className="text-[14px] font-semibold font-poppins text-[#252628] text-center">
            {testimonials[currentIndex].name}
          </p>
          <p className="text-[10px] font-normal font-poppins text-[#252628] text-center">
            {testimonials[currentIndex].designation}
          </p>
          <h3 className="text-[16px] font-poppins font-bold text-[#252628] text-left capitalize mt-4">
            {testimonials[currentIndex].platform}
          </h3>
          <p className="mt-4 font-poppins text-[12px] text-left text-[#5A5A5A]">
            {testimonials[currentIndex].message}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TemoignageSlider;
