import React from "react";
import AmbulanceFast from "../../../assets/accueilImages/NosServicesSectionSvgs/Ambulance - Fast.svg";
import Ambulance from "../../../assets/accueilImages/NosServicesSectionSvgs/Ambulance.svg";
import EmergencyLight from "../../../assets/accueilImages/NosServicesSectionSvgs/Emergency Light - 2.svg";
import Location from "../../../assets/accueilImages/NosServicesSectionSvgs/Location.svg";
import Wheelchair from "../../../assets/accueilImages/NosServicesSectionSvgs/Wheelchair.svg";
import { FaWhatsapp } from "react-icons/fa";
import { PrimaryButton } from "../../../ui";

const cardData = [
  {
    border: "border-[1px] lg:border-b-[1px] lg:border-r-[1px]",
    title: "Ambulance médicalisée",
    description:
      "Transport sécurisé avec un personnel qualifié pour les cas critiques nécessitant une surveillance médicale constante.",
    linkText: "Lire plus",
    svgPath: Ambulance,
  },
  {
    border: "border-[1px] lg:border-x-[1px] lg:border-b-[1px]",
    title: "Ambulance simple",
    description:
      "Transport pour les patients ne nécessitant pas de surveillance médicale en continu, mais qui ont besoin d'un transport en toute sécurité.",
    linkText: "Lire plus",
    svgPath: EmergencyLight,
  },
  {
    border: "border-[1px] lg:border-b-[1px] lg:border-l-[1px]",
    title: "Ambulance par couveuse",
    description:
      "Transport spécialisé pour les nouveau-nés nécessitant une surveillance en couveuse.",
    svgPath: AmbulanceFast,
  },
  {
    border: "border-[1px] lg:border-y-[1px] lg:border-r-[1px]",
    title: "Ambulance Décés",
    description:
      "Services funéraires respectant les rites musulmans, avec un accompagnement complet pour les familles.",
    svgPath: Ambulance,
  },
  {
    border: "border-[1px] lg:border-y-[1px] lg:border-l-[1px]",
    title: "Location de matériel médical",
    description:
      "Nous proposons une large gamme de matériel médical en location, allant des fauteuils roulants aux lits médicalisés.",
    svgPath: Wheelchair,
  },
  {
    border: "border-[1px] lg:border-[1px]",
    title: "Médecin et Infirmier à domicile",
    description:
      "Consultation médicale à domicile pour éviter les déplacements et recevoir des soins en toute tranquillité.",
    svgPath: Location,
  },
];

const NosServicesSection = () => {
  return (
    <section className="px-[34px] md:px-[68px] py-[28px]">
      <header className="text-center mb-[20px] mt-[41px] md:mb-[50px] md:mt-[58.38px]">
        <h2 className="text-[24px] md:text-[48px] font-poppins font-semibold text-[#1F264C]">
          Nos Services
        </h2>
      </header>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {cardData.map((card, index) => (
          <article
            key={index}
            className={`w-full sm:min-w-[250px] md:min-w-[300px] lg:min-w-[354px] h-[273px] mt-[22px] px-[16px] sm:px-[24px] py-[40.5px] bg-white ${card.border} border-[#E1E4ED] rounded-[8px] flex flex-col justify-center`}
          >
            <div
              className="w-[54px] h-[54px] flex items-center justify-center rounded-[4.5px] bg-[#F1F5FD] mb-[21px]"
              style={{ backgroundSize: "10px 9.75px" }}
            >
              <img
                src={card.svgPath}
                alt={`Icône représentant ${card.title}`}
                className="w-[35px] h-[35px] text-[#2E3F91]"
              />
            </div>
            <h3 className="mb-[21px] text-[16px] font-poppins font-extrabold text-[#2E3F91]">
              {card.title}
            </h3>
            <p className="mb-[21px] font-light font-poppins text-[12px] text-[#1F264C]">
              {card.description}
            </p>
            <a href="/#">
              <span className="text-[14px] font-medium text-[#2E3F91]">
                {card.linkText}
              </span>
            </a>
          </article>
        ))}
      </div>
      <PrimaryButton
        name={
          <>
            <FaWhatsapp className="mr-2 text-[22px]" />
            Contactez Nous
          </>
        }
        variant="whatsappPrimaryButton"
        className="mx-auto mt-[22px]"
        onClick={() => (window.location.href = "tel:+212656566358")}
      />
    </section>
  );
};

export default NosServicesSection;
