import { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import image_5 from "../../../assets/accueilImages/Gallery/image 5.webp";
import image_6 from "../../../assets/accueilImages/Gallery/image 6.webp";
import image_7 from "../../../assets/accueilImages/Gallery/WhatsApp Image 2024-09-04 at 22.26.37.jpeg";
import image_1 from "../../../assets/accueilImages/Gallery/image1.jpeg";

const GalleryComponent = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const images = [image_5, image_6, image_7, image_1];
  const totalImages = images.length;

  // Responsive logic: visible images vary based on screen size
  const visibleImages =
    window.innerWidth >= 1024 ? 3 : window.innerWidth >= 640 ? 2 : 1;

  const imageWidth = 349; // Image width
  const spacing = 22; // Space between images (mx-[11px] on each side)

  const handleNext = () => {
    if (isAnimating || currentIndex >= totalImages - visibleImages) return; // Prevent clicking while animating
    setIsAnimating(true);
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, totalImages - visibleImages)
    );
  };

  const handlePrev = () => {
    if (isAnimating || currentIndex <= 0) return; // Prevent clicking while animating
    setIsAnimating(true);
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  return (
    <section
      className="w-[372px] lg:w-[1101px] bg-white mt-[120px] mb-[200px] mx-auto px-4"
      aria-labelledby="gallery-heading"
    >
      <header className="text-center">
        <h2
          id="gallery-heading"
          className="text-[24px] lg:text-[48px] font-bold font-poppins text-[#1F264C] mb-[24px]"
        >
          Visite de notre galerie
        </h2>
        <p className="text-[#1F264C] text-[12px] lg:text-[18px] font-normal max-w-[579.37px] mx-auto">
          Découvrez notre collection d'images qui mettent en avant nos produits
          et services. Parcourez les différentes sections pour en savoir plus
          sur notre entreprise.
        </p>
      </header>
      <div className="relative mt-[32px] flex justify-center items-center">
        <button
          onClick={handlePrev}
          disabled={currentIndex === 0}
          className={`absolute lg:left-[2%] left-[4%] z-10 p-[11px] rounded-[6px] border border-[#3F65D1] shadow hover:shadow-md ${
            currentIndex === 0
              ? "cursor-not-allowed bg-[#F1F5FD]"
              : "bg-[#3F65D1]"
          }`}
          aria-label="Previous image"
        >
          <FaArrowLeft
            className={`${
              currentIndex === 0 ? "text-[#3F65D1]" : "text-white"
            }`}
          />
        </button>
        <div className="flex justify-center relative w-full md:w-[700px] lg:w-[1101px] h-[354px] overflow-hidden">
          <div
            className={`absolute inset-0 flex transition-transform duration-500 ${
              isAnimating ? "ease-out" : "ease-in"
            }`}
            style={{
              transform: `translateX(-${
                currentIndex * (imageWidth + spacing)
              }px)`,
            }}
            onTransitionEnd={() => setIsAnimating(false)}
          >
            {images.map((image, index) => (
              <figure
                className="w-[349px] h-[354px] rounded-[6px] overflow-hidden flex-shrink-0 mx-[11px]"
                key={`${image}-${index}`}
              >
                <img
                  src={image}
                  alt={`Gallery ${index + 1}`}
                  className="w-full h-full object-cover"
                />
                <figcaption className="sr-only">
                  Gallery image {index + 1}
                </figcaption>
              </figure>
            ))}
          </div>
        </div>
        <button
          onClick={handleNext}
          disabled={currentIndex >= totalImages - visibleImages}
          className={`absolute right-[1%] z-10 p-[11px] rounded-[6px] border border-[#3F65D1] shadow hover:shadow-md ${
            currentIndex >= totalImages - visibleImages
              ? "cursor-not-allowed bg-[#F1F5FD]"
              : "bg-[#3F65D1]"
          }`}
          aria-label="Next image"
        >
          <FaArrowRight
            className={`${
              currentIndex >= totalImages - visibleImages
                ? "text-[#3F65D1]"
                : "text-white"
            }`}
          />
        </button>
      </div>
    </section>
  );
};

export default GalleryComponent;
