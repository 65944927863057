import NosValeursImg from "../../../assets/accueilImages/NosValeurs/NosValeursImg.webp";
import NosValeursImgPhone from "../../../assets/accueilImages/NosValeurs/NosValeursImgPhone.png";
import { FaWhatsapp } from "react-icons/fa";
import { PrimaryButton } from "../../../ui";

const NosValeurs = () => {
  return (
    <section className="bg-white py-12" aria-label="Nos Valeurs">
      {/* Desktop Version */}
      <div className="hidden lg:flex flex-col items-center">
        {/* Title */}
        <h2 className="text-[48px] font-semibold font-poppins text-[#1F264C] mb-[50px] mt-[80px]">
          Nos Valeurs
        </h2>

        {/* Content Section */}
        <div className="flex flex-col md:flex-row items-center justify-center">
          {/* Left Text Section */}
          <div className="relative w-[731px] ml-[84.5px] p-6">
            {/* Background image applied via CSS class */}
            <div className="absolute bg-nos-valeurs bg-no-repeat -left-[20%] -top-[40%] w-[904px] h-[943.36px]"></div>
            <div className="relative z-10 w-[455px]">
              {/* Values */}
              <div>
                <h3 className="text-[16px] font-semibold font-poppins text-[#2E3F91] mb-[8px]">
                  Proximité
                </h3>
                <p className="text-[16px] text-[#1F264C] mb-[50px]">
                  Nous sommes présents à vos côtés, où que vous soyez, avec une
                  réactivité sans faille.
                </p>
              </div>
              <div>
                <h3 className="text-[16px] font-semibold font-poppins text-[#2E3F91] mb-[8px]">
                  Professionnalisme
                </h3>
                <p className="text-[16px] text-[#1F264C] mb-[50px]">
                  Nos équipes sont formées pour intervenir efficacement dans
                  toutes les situations médicales d'urgence.
                </p>
              </div>
              <div>
                <h3 className="text-[16px] font-semibold font-poppins text-[#2E3F91] mb-[8px]">
                  Respect
                </h3>
                <p className="text-[16px] text-[#1F264C] mb-[50px]">
                  Nous respectons les personnes et les familles que nous
                  servons, en tenant compte de leurs croyances et traditions.
                </p>
              </div>
              <div>
                <h3 className="text-[16px] font-semibold font-poppins text-[#2E3F91] mb-[8px]">
                  Humanité
                </h3>
                <p className="text-[16px] text-[#1F264C] mb-[50px]">
                  Au-delà de notre profession, nous plaçons l'humain au centre
                  de nos actions.
                </p>
              </div>
            </div>
            <div className="relative z-10 mt-6">
              <PrimaryButton
                name={
                  <>
                    <FaWhatsapp className="mr-2 text-[22px]" />
                    Contactez Nous
                  </>
                }
                variant="whatsappPrimaryButton"
                className="mx-[128px] mt-[50px]"
                onClick={() => (window.location.href = "tel:+212656566358")}
              />
            </div>
          </div>

          {/* Right Image Section */}
          <div className="relative">
            <img
              src={NosValeursImg}
              alt="Illustration des valeurs de l'entreprise"
              className="w-[731px] h-[679px]"
            />
          </div>
        </div>
      </div>

      {/* Mobile Version */}
      <div className="flex flex-col lg:hidden items-center relative">
        {/* Title */}
        <h2 className="text-[32px] font-semibold font-poppins text-[#1F264C] mb-[30px] mt-[40px]">
          Nos Valeurs
        </h2>

        {/* Content Section */}
        <div className="flex flex-col items-center justify-center relative z-10">
          {/* Image Section */}
          <div className="relative mb-6 -z-20">
            <img
              src={NosValeursImgPhone}
              alt="Illustration des valeurs de l'entreprise"
              className="w-[100%] h-auto"
            />
          </div>

          {/* Values Section */}
          <div className="w-[370px] mx-auto">
            <div className="absolute top-[34%] inset-0 bg-nos-valeurs bg-no-repeat bg-cover -z-10"></div>

            <div className="mb-6">
              <h3 className="text-[12px] font-extrabold font-poppins text-[#2E3F91] mb-[10px]">
                Proximité
              </h3>
              <p className="text-[12px] font-poppins text-[#1F264C]">
                Nous sommes présents à vos côtés, où que vous soyez, avec une
                réactivité sans faille.
              </p>
            </div>
            <div className="mb-6">
              <h3 className="text-[12px] font-extrabold font-poppins text-[#2E3F91] mb-[10px]">
                Professionnalisme
              </h3>
              <p className="text-[12px] font-poppins text-[#1F264C]">
                Nos équipes sont formées pour intervenir efficacement dans
                toutes les situations médicales d'urgence.
              </p>
            </div>
            <div className="mb-6">
              <h3 className="text-[12px] font-extrabold font-poppins text-[#2E3F91] mb-[10px]">
                Respect
              </h3>
              <p className="text-[12px] font-poppins text-[#1F264C]">
                Nous respectons les personnes et les familles que nous servons,
                en tenant compte de leurs croyances et traditions.
              </p>
            </div>
            <div className="mb-[20px]">
              <h3 className="text-[12px] font-extrabold font-poppins text-[#2E3F91] mb-[10px]">
                Humanité
              </h3>
              <p className="text-[12px] font-poppins text-[#1F264C]">
                Au-delà de notre profession, nous plaçons l'humain au centre de
                nos actions.
              </p>
            </div>
          </div>

          {/* WhatsApp Button */}
          <PrimaryButton
            name={
              <>
                <FaWhatsapp className="mr-2 text-[22px]" />
                Contactez Nous
              </>
            }
            variant="whatsappPrimaryButton"
            className="mt-6"
            onClick={() => (window.location.href = "tel:+212656566358")}
          />
        </div>
      </div>
    </section>
  );
};

export default NosValeurs;
