import {
  FaWhatsapp,
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaArrowRight,
} from "react-icons/fa";
import logo from "../../assets/accueilImages/headerLogo/Logo.png";
import { PrimaryButton } from "../../ui";

const Footer = () => {
  return (
    <footer className="bg-white py-8 border-t border-gray-200">
      <div className="container mx-auto px-4">
        {/* Mobile Layout */}
        <div className="block lg:hidden w-[348px] mx-auto">
          <div className="flex flex-col items-center mb-6">
            <img
              src={logo}
              alt="Injad Logo"
              className="w-[70px] h-[70px] mb-[28px]"
            />
            <p className="text-[#333333] text-[16px] text-center w-[376px] mb-[28px]">
              Injad est une société marocaine basée à Khouribga, spécialisée
              dans les services d'ambulance et d'urgence médicale.
            </p>
            <div className="flex flex-row items-center space-x-4">
              <PrimaryButton
                name="Appelez Nous"
                variant="primaryButton"
                className={
                  "bg-[#2E3F91] w-[181.75px] h-[38.5px] text-[14.61px]"
                }
              />
              <PrimaryButton
                name={
                  <>
                    <FaWhatsapp className="mr-2 text-[22px]" />
                    Contactez Nous
                  </>
                }
                variant="whatsappPrimaryButton"
                onClick={() => (window.location.href = "tel:+212656566358")}
                className={"w-[181.75px] h-[38.5px] text-[14.61px]"}
              />
            </div>
            <div className="mt-[51px] text-center">
              <p className="mb-2 font-semibold text-[18px] text-[#1F264C] text-left">
                Abonnez-vous à notre newsletter:
              </p>
              <div className="flex relative">
                <input
                  type="email"
                  placeholder="Entrez votre email"
                  aria-label="Adresse e-mail"
                  className="flex-1 border border-gray-300 mb-[30px] py-2 px-4 rounded-[8px] w-[370px] lg:w-[475px] h-[49px]"
                />
                <button className="absolute right-[8px] top-[11%] text-[12px] font-semibold flex justify-center items-center my-auto bg-[#1F264C] text-white w-[92px] h-[33px] rounded-[6px]">
                  Envoyer
                  <FaArrowRight className="ml-[10px] w-[9px] h-[9px]" />
                </button>
              </div>
              <p className="mb-2 text-[#1F264C] text-[12px] font-poppins font-semibold">
                Suivez-nous:
              </p>
              <div className="flex justify-center space-x-3">
                <a
                  href="https://www.facebook.com/p/Ambulance-injad-khouribga-100063661796168/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Facebook"
                >
                  <div className="bg-blue-700 text-white p-2 rounded-[6px] cursor-pointer">
                    <FaFacebookF />
                  </div>
                </a>
                <a href="/#" aria-label="Instagram">
                  <div className="bg-pink-500 text-white p-2 rounded-[6px] cursor-pointer">
                    <FaInstagram />
                  </div>
                </a>
                <a href="/#" aria-label="YouTube">
                  <div className="bg-red-600 text-white p-2 rounded-[6px] cursor-pointer">
                    <FaYoutube />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* PC Layout */}
        <div className="hidden lg:w-[1104px] lg:mx-auto lg:flex lg:justify-between">
          {/* Left Section */}
          <div className="w-[376px] mb-6 lg:mb-0">
            <div className="mb-[28px]">
              <img
                src={logo}
                alt="Injad Logo"
                className="w-[70px] h-[70px] mb-[28px]"
              />
              <p className="text-[#333333] text-[16px]">
                Injad est une société marocaine basée à Khouribga, spécialisée
                dans les services d'ambulance et d'urgence médicale.
              </p>
            </div>
            <div className="flex flex-wrap items-center space-x-4">
              <PrimaryButton
                name="Appelez Nous"
                variant="primaryButton"
                className="bg-[#2E3F91] w-[135.13px] h-[38.65px]"
              />
              <PrimaryButton
                name={
                  <>
                    <FaWhatsapp className="mr-2 text-[22px]" />
                    Contactez Nous
                  </>
                }
                variant="whatsappPrimaryButton"
                onClick={() => (window.location.href = "tel:+212656566358")}
              />
            </div>
          </div>

          {/* Right Section */}
          <div className="w-[475px]">
            <h3 className="font-semibold font-poppins text-[#1F264C] text-[24px] mb-[24px]">
              Abonnez-vous à notre newsletter
            </h3>
            <div className="flex relative">
              <input
                type="email"
                placeholder="Entrez votre email"
                aria-label="Adresse e-mail"
                className="flex-1 border border-gray-300 py-2 px-4 rounded-[8px] w-full lg:w-[475px] h-[49px]"
              />
              <button className="absolute right-[8px] top-[15%] text-[12px] font-semibold flex justify-center items-center my-auto bg-[#1F264C] text-white w-[92px] h-[33px] rounded-[6px]">
                Envoyer
                <FaArrowRight className="ml-[10px] w-[9px] h-[9px]" />
              </button>
            </div>
            <div className="flex flex-col items-start mt-[51px]">
              <p className="mb-2">Suivez-nous:</p>
              <div className="flex space-x-3">
                <a
                  href="https://www.facebook.com/p/Ambulance-injad-khouribga-100063661796168/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Facebook"
                >
                  <div className="bg-blue-700 text-white p-2 rounded-[6px] cursor-pointer">
                    <FaFacebookF />
                  </div>
                </a>
                <a href="/#" aria-label="Instagram">
                  <div className="bg-pink-500 text-white p-2 rounded-[6px] cursor-pointer">
                    <FaInstagram />
                  </div>
                </a>
                <a href="/#" aria-label="YouTube">
                  <div className="bg-red-600 text-white p-2 rounded-[6px] cursor-pointer">
                    <FaYoutube />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Line Divider */}
      <div className="w-[372px] lg:w-[1104px] mx-auto mt-[72.5px] border-t border-gray-300"></div>
      <div className="container mx-auto my-[24px] px-4 text-center text-gray-600 text-sm">
        <p>&copy; 2024 XOCOM Agency. Tous droits réservés.</p>
      </div>
    </footer>
  );
};

export default Footer;
