import background from "../../../assets/accueilImages/CommentCaMarcheSectionImg/CommentCaMarcheBg.webp";
import { FaWhatsapp } from "react-icons/fa";
import { PrimaryButton } from "../../../ui";
import { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const CommentCaMarcheSection = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    {
      number: 1,
      title: "Contactez-nous",
      description:
        "Vous pouvez nous joindre 24/7 via téléphone ou notre WhatsApp.",
    },
    {
      number: 2,
      title: "Évaluation de vos besoins",
      description:
        "Nos équipes vous guideront et évalueront vos besoins médicaux spécifiques.",
    },
    {
      number: 3,
      title: "Intervention-nous",
      description:
        "En cas d’urgence, nos équipes d’ambulance interviendront immédiatement.",
    },
  ];

  const handlePrev = () => {
    setCurrentStep(currentStep === 0 ? steps.length - 1 : currentStep - 1);
  };

  const handleNext = () => {
    setCurrentStep(currentStep === steps.length - 1 ? 0 : currentStep + 1);
  };

  return (
    <section
      style={{
        background: `url(${background}) no-repeat center, linear-gradient(rgba(54, 81, 191, 1), rgba(25, 38, 89, 1))`,
        backgroundSize: "cover",
      }}
      className="pt-[30px] pb-[55px] px-1 md:px-8 text-center relative font-bold overflow-auto"
    >
      {/* Title */}
      <h1
        className="text-3xl md:text-4xl lg:text-[48px] font-semibold font-poppins mb-[50px] text-[#F1F5FD]"
        aria-label="Comment Ça Marche - Process Explanation"
      >
        Comment Ça Marche
      </h1>

      {/* Mobile Version - Carousel */}
      <div className="flex items-center justify-center relative mb-[33.25px] mx-auto text-[#F1F5FD] [text-shadow:0_0px_10px_rgba(241,_245,_253,_1)] sm:hidden">
        <button
          onClick={handlePrev}
          className="absolute left-0 px-4"
          aria-label="Previous Step"
        >
          <FaChevronLeft className="text-white text-2xl" />
        </button>

        <div className="w-[269.25px] flex justify-center">
          <div className="border border-[#F1F5FD] text-left w-full h-[269px] px-[24px] pt-[24px] mx-auto rounded-[10px]">
            <h2 className="font-extrabold text-2xl mb-[11px]">
              {steps[currentStep].number}
            </h2>
            <h3 className="text-[12.19px] font-poppins font-extrabold mb-[11px]">
              {steps[currentStep].title}
            </h3>
            <p className="text-[12.19px] font-poppins font-normal leading-[24px]">
              {steps[currentStep].description}
            </p>
          </div>
        </div>

        <button
          onClick={handleNext}
          className="absolute right-0 px-4"
          aria-label="Next Step"
        >
          <FaChevronRight className="text-white text-2xl" />
        </button>
      </div>

      {/* Desktop Version - Grid */}
      <div className="hidden lg:flex md:flex-cols-2 lg:flex-cols-3 justify-center mx-auto text-[#F1F5FD] [text-shadow:0_0px_10px_rgba(241,_245,_253,_1)]">
        {/* Step 1 */}
        <article className="border border-[#F1F5FD] text-left w-full md:w-[353px] h-[269px] px-[24px] pt-[24px] mx-[11px] rounded-[10px]">
          <h2 className="font-extrabold text-2xl mb-[11px]">1</h2>
          <h3 className="text-[16px] font-poppins font-extrabold mb-[11px]">
            Contactez-nous
          </h3>
          <p className="text-[16px] font-poppins font-normal leading-[24px]">
            Vous pouvez nous joindre 24/7 via téléphone ou notre WhatsApp.
          </p>
        </article>

        {/* Step 2 */}
        <article className="border border-white text-left w-full md:w-[353px] h-[269px] px-[24px] pt-[24px] mx-[11px] rounded-[10px]">
          <h2 className="font-extrabold text-2xl mb-[11px]">2</h2>
          <h3 className="text-[16px] font-poppins font-extrabold mb-[11px]">
            Évaluation de vos besoins
          </h3>
          <p className="text-[16px] font-poppins font-normal leading-[24px]">
            Nos équipes vous guideront et évalueront vos besoins médicaux
            spécifiques.
          </p>
        </article>

        {/* Step 3 */}
        <article className="border border-white text-left w-full md:w-[353px] h-[269px] px-[24px] pt-[24px] mx-[11px] rounded-[10px]">
          <h2 className="font-extrabold text-2xl mb-[11px]">3</h2>
          <h3 className="text-[16px] font-poppins font-extrabold mb-[11px]">
            Intervention-nous
          </h3>
          <p className="text-[16px] font-poppins font-normal leading-[24px]">
            En cas d'urgence, nos équipes d'ambulance interviendront
            immédiatement. Pour des services à domicile, nous planifions une
            intervention rapide selon vos disponibilités.
          </p>
        </article>
      </div>

      {/* Buttons Section */}
      {/* Mobile Buttons */}
      <div className="flex flex-col items-center gap-4 sm:hidden">
        <a
          href="https://maps.app.goo.gl/cbpL8BCp7DhAiyDk8"
          target="_blank"
          rel="noreferrer"
          className="text-[#2E3F91] bg-[#F1F5FD] flex justify-center items-center font-normal border-[#2E3F91] border-2 rounded-[6px] text-[16px] w-[182px] h-[45px] px-[24px]"
          aria-label="View on Map"
        >
          Voir sur la carte
        </a>
        <PrimaryButton
          name={
            <>
              <FaWhatsapp className="mr-2 text-[22px]" />
              Contactez Nous
            </>
          }
          variant="whatsappPrimaryButton"
          onClick={() => (window.location.href = "tel:+212656566358")}
          aria-label="Contact Us via WhatsApp"
        />
      </div>

      {/* Desktop Buttons */}
      <div className="mt-[50px] justify-center gap-4 hidden sm:flex">
        <a
          href="https://maps.app.goo.gl/cbpL8BCp7DhAiyDk8"
          target="_blank"
          rel="noreferrer"
          className="text-[#2E3F91] bg-[#F1F5FD] flex justify-center items-center font-normal border-[#2E3F91] border-2 rounded-[6px] text-[16px] w-[169px] h-[45px] px-[18px]"
          aria-label="View on Map"
        >
          Voir sur la carte
        </a>
        <PrimaryButton
          name={
            <>
              <FaWhatsapp className="mr-2 text-[22px]" />
              Contactez Nous
            </>
          }
          variant="whatsappPrimaryButton"
          onClick={() => (window.location.href = "tel:+212656566358")}
          aria-label="Contact Us via WhatsApp"
        />
      </div>
    </section>
  );
};

export default CommentCaMarcheSection;
