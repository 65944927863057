import { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import logo from "../../assets/accueilImages/headerLogo/Logo.png";
import { Link as ScrollLink } from "react-scroll";
import { PrimaryButton } from "../../ui";

export default function Header() {
  const [activeLink, setActiveLink] = useState("Accueil");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
    setIsMenuOpen(false);
  };

  return (
    <header className="font-sans tracking-wide relative z-50">
      {/* Desktop Contact Information */}
      <section className="hidden lg:block bg-[#3448a1] text-white text-[12px]">
        <div className="container mx-auto flex justify-between items-center lg:h-[42px] lg:w-[1104px] px-4 md:px-10">
          <div>
            <a
              href="https://wa.me/+212656566358"
              target="_blank"
              rel="noreferrer"
              aria-label="Contact us on WhatsApp"
            >
              +212 6 56 56 63 58
            </a>
          </div>
          <div>
            <a
              href="https://maps.app.goo.gl/cbpL8BCp7DhAiyDk8"
              target="_blank"
              rel="noreferrer"
              aria-label="Find us on Google Maps"
            >
              Rue Gabon, Hay Nahda, Khouribga
            </a>
          </div>
          <div>
            <a href="mailto:ambulanceinjad.kh@gmail.com" aria-label="Email us">
              ambulanceinjad.kh@gmail.com
            </a>
          </div>
        </div>
      </section>

      {/* Main Header */}
      <div className="bg-[#F6F9FF] lg:py-4">
        <div className="lg:w-[1050px] w-full mx-auto flex items-center justify-between gap-4 lg:h-[118px] px-4 md:px-0">
          {/* Logo */}
          <ScrollLink
            to="heroSection"
            smooth={true}
            duration={500}
            className="cursor-pointer"
            aria-label="Go to hero section"
          >
            <img src={logo} alt="Injad Logo" className="hidden lg:block w-16" />
          </ScrollLink>

          {/* Desktop Navigation */}
          <nav
            className="hidden lg:flex lg:gap-x-[24px]"
            aria-label="Main navigation"
          >
            <ScrollLink
              to="heroSection"
              smooth={true}
              duration={500}
              className={`cursor-pointer font-base block text-[16px] py-2 ${
                activeLink === "Accueil"
                  ? "font-bold text-[#3651BF]"
                  : "text-[#3651BF]"
              }`}
              onClick={() => handleLinkClick("Accueil")}
              aria-label="Navigate to Home"
            >
              Accueil
            </ScrollLink>
            <ScrollLink
              to="quiSommesNousHero"
              smooth={true}
              duration={500}
              className={`cursor-pointer font-base block text-[16px] py-2 ${
                activeLink === "A propos"
                  ? "font-bold text-[#3651BF]"
                  : "text-[#3651BF]"
              }`}
              onClick={() => handleLinkClick("A propos")}
              aria-label="Navigate to About Us"
            >
              A propos
            </ScrollLink>
            <ScrollLink
              to="nosServicesSection"
              smooth={true}
              duration={500}
              className={`cursor-pointer font-base block text-[16px] py-2 ${
                activeLink === "Nos Services"
                  ? "font-bold text-[#3651BF]"
                  : "text-[#3651BF]"
              }`}
              onClick={() => handleLinkClick("Nos Services")}
              aria-label="Navigate to Our Services"
            >
              Nos Services
            </ScrollLink>
            <ScrollLink
              to="temoignageSlider"
              smooth={true}
              duration={500}
              className={`cursor-pointer font-base block text-[16px] py-2 ${
                activeLink === "Contact"
                  ? "font-bold text-[#3651BF]"
                  : "text-[#3651BF]"
              }`}
              onClick={() => handleLinkClick("Contact")}
              aria-label="Navigate to Contact"
            >
              Contact
            </ScrollLink>
            <div className="hidden lg:block">
              <PrimaryButton
                name={
                  <>
                    <FaWhatsapp className="mr-2 text-[22px]" />
                    Contactez Nous
                  </>
                }
                variant="whatsappPrimaryButton"
                className="ml-[108px] cursor-pointer"
                onClick={() => (window.location.href = "tel:+212656566358")}
                aria-label="Call us on WhatsApp"
              />
            </div>
          </nav>

          {/* Mobile layout: Logo and WhatsApp button */}
          <div className="lg:hidden flex w-[430px] h-[90px] justify-between items-center">
            <ScrollLink
              to="heroSection"
              smooth={true}
              duration={500}
              className="cursor-pointer"
              aria-label="Go to hero section"
            >
              <img src={logo} alt="Injad Logo" className="w-[50px]" />
            </ScrollLink>
            <button
              onClick={() => (window.location.href = "tel:+212656566358")}
              className="flex items-center justify-center text-[#ffffff] bg-[#25D366] rounded-[6px] w-[42px] h-[42px] text-[22px]"
              aria-label="Call us on WhatsApp"
            >
              <FaWhatsapp />
            </button>
          </div>

          {/* Mobile Menu Toggle */}
          <button
            id="toggleOpen"
            className="lg:hidden cursor-pointer"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle mobile menu"
          >
            {/* Your mobile menu SVG icon */}
          </button>
        </div>
      </div>
    </header>
  );
}
